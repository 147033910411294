<template>
  <div class="opage">
    <headGoBack :iCustomizdde="true">
      <template #default>
        <span> 倉庫保管費用 </span>
      </template>
    </headGoBack>
    <div class="oheaBox">
      <h1>倉庫保管費用</h1>
      <div class="retentionTime">
        <div class="titBox">無料保管期間</div>
        <div class="conBox">
          <div class="conItem">
            <p class="oname">一般会員</p>
            <p><span class="redFont">60日</span>無料保管</p>
          </div>
          <hr />
          <div class="conItem">
            <p class="oname">定額会員</p>
            <p><span class="redFont">90日</span>無料保管</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row1">
      <h2>カウント期間</h2>
      <p>
        一つの注文書に於いて、全ての商品がマイページ <br />
        『倉庫』に入った日（出荷可能日）~ <br />
        配送依頼見積書を提出した日（出荷依頼日）の前日まで。
      </p>
    </div>
    <div class="row2">
      <div class="dividerLine">
        <hr />
        <div class="fontBox">
          <span> SERVICE CHARGE </span>
          <span> 01 </span>
        </div>
      </div>
      <h2>無料保管期限を過ぎた場合の保管手数料について</h2>
      <p>★1m³毎に、５元/日</p>
      <p>＜例＞1m³→5元×30日=150元</p>
      <p class="redFont">
        ※1m³(イチエムスリー)に満たない場合は、1m³で計算されます。
      </p>
      <p class="redFont">
        ※容積重量計算と同じく、小数点以下切上げの為1.1m³→2m³換 算となります。
      </p>
      <p class="redFont">
        ※商品はバーコード管理されており、システムで自動計算の上、
        無料期間を過ぎますと、
      </p>
      <p>マイページ残高より毎日引き落としとなります。（毎翌日24時頃課金）</p>
      <div class="hei35"></div>
      <div class="dividerLine">
        <hr />
        <div class="fontBox">
          <span> SERVICE CHARGE </span>
          <span> 02 </span>
        </div>
      </div>
      <h2>その他、ご注意点</h2>
      <p>
        ◆長期（180日以上）連絡の取れないお客様について、メールや電話で最終連絡後も返答が無い場合、商品の所有権を放棄したも
        のとし、最終連絡から７日間後に自動廃棄させて頂きます。
      </p>
      <p>
        ◆中国の長期休み等、弊社が指定する休日期間中も日数はカウントされますので、なるべく長期休業前には、配送指示書をご提出
        下さいませ。
      </p>
      <p>
        配送指示書をご提出頂けましたら、カウントは停止致します。ただし、提出後、長期国際送料支払いがない場合、且つ連絡が取
        れない場合は上記と同じく廃棄処分となりますので、予めご了承ください。
      </p>
      <div class="hei30"></div>
      <p>
        ご不明点がありましたら、マイページのチャット、もしくはチャットワーク、ＨＰのお問合せフォームからお待ちしております。
      </p>
    </div>
    <footBar />
  </div>
</template>

<script setup>
import { getCurrentInstance, ref } from "vue";
import headGoBack from "@/components/headGoBack";
import footBar from "@/components/footBar/index.vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------

//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped>
.hei30 {
  height: 30px;
}
.hei35 {
  height: 35px;
}
.redFont {
  color: #b4272b;
}
.opage {
  background-color: #f6f6f6;
}
.oheaBox {
  background-image: url("https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6491582318891.jpg");
  background-size: 100% 100%;
  height: 450px;
  padding: 150px 0 0;
  position: relative;
  h1 {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    color: white;
  }
  .retentionTime {
    position: absolute;
    width: 630px;
    top: 320px;
    left: 50%;
    transform: translateX(-50%);
    .titBox {
      background: #b4272b;
      border-radius: 10px 10px 0px 0px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-weight: 600;
      color: #ffffff;
    }
    .conBox {
      display: flex;
      align-items: center;
      border-radius: 0px 0px 10px 10px;
      overflow: hidden;
      .conItem {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        height: 162px;
        p {
          font-size: 24px;
          line-height: 1;
          span {
            font-size: 24px;
            line-height: 1;
          }
        }
        p.oname {
          font-size: 28px;
          line-height: 1;
          font-weight: 600;
          color: #333333;
          margin-bottom: 17px;
        }
      }
      hr {
        border: none;
        background-color: #dfdfdf;
        height: 50px;
        width: 1px;
      }
    }
  }
}
.row1 {
  padding: 191px 0 60px;
  h2 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    margin-bottom: 35px;
  }
  p {
    text-align: center;
    line-height: 1.3;
    font-size: 24px;
  }
}
.row2 {
  background-color: white;
  padding: 60px 30px;
  .dividerLine {
    margin-bottom: 30px;
    hr {
      background-color: #dfdfdf;
      margin-bottom: 8px;
      height: 1px;
      border: none;
    }
    .fontBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #dfdfdf;
        font-size: 20px;
        font-style: italic;
      }
    }
  }
  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  p {
    font-size: 24px;
    line-height: 1.2;
  }
}
</style>
